import React from "react"
import LineBreak from "../elements/line-break"
import Tags from "../elements/tags"

const TagsAndReferences = ({ references, tags, linebreak }) => {
  const referenceList = () => {
    return (
      <ul className="tagsrefs__references__list">
        {references.map((reference, id) => (
          <li key={id}>
            <a href={reference.link} target="_blank" rel="noreferrer">
              {reference.link}
            </a>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <section className="tagsrefs">
      <div className="tagsrefs__outer">
        <div className="tagsrefs__inner">
          {linebreak && <LineBreak data={linebreak} />}
          {references.length ? (
            <div className="tagsrefs__references">
              <h3 className="tagsrefs__heading">BLOG REFERENCES</h3>
              {referenceList()}
            </div>
          ) : (
            ""
          )}
          {tags.length ? (
            <div className="tagsrefs__tags">
              <h3 className="tagsrefs__heading">BLOG TAGS</h3>
              <Tags data={tags} />
            </div>
          ) : (
            ""
          )}
          {linebreak && <LineBreak data={linebreak} />}
        </div>
      </div>
    </section>
  )
}

export default TagsAndReferences

import { Link } from "gatsby"
import React from "react"
import Close from "../../images/tag-close.svg";

const Tags = ({ data }) => {
  const tag = () => {
    return (
      <div className="tags">
        {data.map((tag, id) => (
          <div className="tags__tag" key={id}>
            <Link to={tag.slug} aria-label={tag.name}>
              <div className="tags__tag__text">{tag.name}<span className="icon"><Close /></span></div>
            </Link>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="tags">
      <div className="tags__outer">{tag()}</div>
    </div>
  )
}

export default Tags

import React from "react"
import { graphql } from "gatsby"
import Layout from "@/components/layout"
import Sections from "@/components/sections"
import Seo from "@/components/seo"
import GetInTouch from "@/components/sections/get-in-touch"
import TagsAndReferences from "../components/sections/tags-and-references"
// import ShareComponent from "../components/elements/share-component"

// const isBrowser = typeof window !== "undefined"


/*
Share Component needs to be fixed, please do not remove comments
*/
const InsightPage = ({ data, pageContext }) => {
  const {
    contentSections,
    metadata,
    // share,
    references,
    tags,
  } = data.strapiInsight

  const global = data.strapiGlobal
  const navbar = data.strapiNavbar
  const footer = data.strapiFooter

  // let width = isBrowser ? window.innerWidth : 0

  return (
    <>
      <Seo seo={metadata} global={global} />
      <Layout
        navbar={navbar}
        footer={footer}
        pageContext={{ ...pageContext }}
        slug="insights"
      >
        <div className="insight">
          <article className="insight__article">
            {/* {width <= 640 ? (
              <div className="insight__shareComponent__top-bottom insight__shareComponent__top-bottom--top">
                <ShareComponent data={share} metadata={metadata} />
              </div>
            ) : <div className="insight__shareComponent">
              <ShareComponent data={share} metadata={metadata} />
          </div>} */}

            <Sections sections={contentSections} />

            {tags.length && references.length ? (
              <TagsAndReferences
                references={references}
                tags={tags}
                linebreak={{ lineheight: "1" }}
              />
            ) : (
              ""
            )}
            {/* {width <= 640 ? (
            <div className="insight__shareComponent__top-bottom insight__shareComponent__top-bottom--bottom">
              <ShareComponent data={share} metadata={metadata} />
            </div>
          ) : null} */}
          </article>
        </div>
        <GetInTouch
          data={{
            __component: "sections.get-in-touch",
            id: 1,
            formGuid: "60ee6d79-fc5e-4e51-93a6-d78a63760a14",
            description:
              "Whether you’re ready to hire a Hooligan or just have a problem or project you want to discuss, drop us your details so we can get in touch. ",
            breadcrumbTitle: {
              id: 4,
              title: "Get in Touch",
              colour: "lightgrey",
              colourOverride: null,
            },
            title: [
              {
                id: 68,
                text: "<",
                style: "bold",
                colour: "green",
                colourOverride: null,
              },
              {
                id: 69,
                text: "Hello ",
                style: "italic",
                colour: "orange",
                colourOverride: null,
              },
              {
                id: 70,
                text: "Hooli",
                style: "bold",
                colour: "white",
                colourOverride: null,
              },
              {
                id: 71,
                text: "/>",
                style: "bold",
                colour: "green",
                colourOverride: null,
              },
            ],
          }}
        />
      </Layout>
    </>
  )
}

export default InsightPage

export const query = graphql`
  fragment GlobalDataInsights on StrapiGlobal {
    favicon {
      localFile {
        publicURL
      }
    }
    id
    metaTitleSuffix
    metadata {
      metaDescription
      metaTitle
      metaKeywords
      shareImage {
        localFile {
          publicURL
        }
      }
    }
  }

  fragment NavbarData on StrapiNavbar {
    button {
      id
      newTab
      text
      type
      url
    }
    id
    links {
      url
      text
      newTab
      id
    }
  }

  fragment FooterData on StrapiFooter {
    id
    columns {
      id
      links {
        id
        newTab
        text
        url
      }
      title
    }
    id
    smallText
    email
    policies {
      id
      newTab
      text
      pdf {
        url
      }
    }
  }

  query DynamicInsightQuery($slug: String!) {
    strapiGlobal(locale: { eq: "en" }) {
      ...GlobalDataInsights
    }
    strapiNavbar {
      ...NavbarData
    }
    strapiFooter {
      ...FooterData
    }
    strapiInsight(slug: { eq: $slug }) {
      id
      title
      blurb
      publishDate
      share {
        facebook
        instagram
        linkedin
        twitter
      }
      metadata {
        metaTitle
        metaDescription
        metaKeywords
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      contentSections
      tags {
        name
        slug
      }
      cardBackground {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 800, height: 500)
          }
        }
      }
      categories {
        name
        slug
      }
      references {
        link
      }
      tags {
        name
        slug
      }
    }
  }
`
